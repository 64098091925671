import { Box, Typography } from "@mui/material";



export default function CopyRight() {

    return (
        <Box
            padding={4}
            paddingBottom={2}
        >
            <Typography align="center">
                Copyright 2023 James M Smillie<br/>
                All Rights Reserved
            </Typography>
        </Box>
    )
}